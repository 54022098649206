/* eslint-disable no-restricted-properties */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

import { connectTranslations } from '@eva/emf/app/shared/utils/translations';
import request, { requestBackend } from '@eva/emf/app/utils/request';
import momentLocaleRu from '@eva/emf/app/shared/moment-locales/ru';
import { localStorageService, localStorageTypes } from '@eva/emf/app/services/localStorage';
import authService from '@eva/emf/app/services/auth';
import { appTypes, baseEndpoint, localHostnames } from '@eva/emf/app/shared/constants';
import { registerAnalyticsSession } from '@eva/emf/app/shared/functions-ts';
import { getParsedUser, isCandidateIntersect } from 'shared/functions';

import {
  signInSuccess,
  signInError,
  signUpSuccess,
  signUpError,
  loadUserProfileSuccess,
  loadUserProfileError,
  restorePasswordSuccess,
  restorePasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  loadPermissionsSuccess,
  loadPermissionsError,
  loadSettingsSuccess,
  loadSettingsError,
} from './actions';
import {
  SIGN_IN,
  SIGN_UP,
  LOAD_USER_PROFILE,
  RESTORE_PASSWORD,
  RESET_PASSWORD,
  LOAD_PERMISSIONS,
  LOAD_SETTINGS,
} from './constants';

const boundResolve = Promise.resolve.bind(Promise);

export function* signIn(action) {
  try {
    const result = yield call(requestBackend, '/authentication/auth/login', {
      method: 'POST',
      body: JSON.stringify({
        ...action.payload,
        token: localStorage.token,
      }),
    });
    if (result.user) {
      const wrongAccountType = isCandidateIntersect(result.user.accountTypes) ? 0 : 1;
      yield put(signInSuccess({ ...result, wrongAccountType }));
    } else {
      yield put(signInError(result.error));
    }
  } catch (err) {
    yield put(signInError(err));
  }
}

export function* signInWatcher() {
  return yield takeLatest(SIGN_IN, signIn);
}

export function* signUp(action) {
  try {
    const payload = yield call(request, `${baseEndpoint}/api/authentication/auth/sign-up`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    });
    localStorageService.deleteItem(localStorageTypes.authTypes);
    const { accountTypes, userId, firstName, lastName, email, activeAccountTypes } = payload.user;
    authService.signIn({
      user: {
        accountTypes,
        activeAccountTypes,
        userId,
        firstName,
        lastName,
        email,
      },
      token: payload.token,
    });
    const userProfile = yield call(requestBackend, '/utils/user');
    yield put(signUpSuccess({ ...payload, userProfile }));
  } catch (err) {
    yield put(signUpError(err));
  }
}

export function* signUpWatcher() {
  return yield takeLatest(SIGN_UP, signUp);
}

export function* getUserProfile() {
  try {
    let customFields;
    try {
      customFields = yield call(requestBackend, '/my/candidate-profile/custom-fields');
    } catch (err) {
      // Do nothing
    }
    const payload = yield call(requestBackend, '/my/candidate-profile');

    localStorage.userDisplayName = payload?.name?.userDisplayName;

    registerAnalyticsSession(appTypes.candidate);

    yield put(
      loadUserProfileSuccess({
        ...payload,
        customFields,
      }),
    );
  } catch (err) {
    yield put(loadUserProfileError(err));
  }
}

export function* loadUserProfile() {
  yield takeLatest(LOAD_USER_PROFILE, getUserProfile);
}

export function* restorePassword(action) {
  try {
    const result = yield call(request, `${baseEndpoint}/api/authentication/auth/restore-password`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    });
    yield put(restorePasswordSuccess(result));
  } catch (err) {
    yield put(restorePasswordError(err));
  }
}

export function* restorePasswordWatcher() {
  return yield takeLatest(RESTORE_PASSWORD, restorePassword);
}

export function* postResetPassword(action) {
  try {
    const result = yield call(request, `${baseEndpoint}/api/authentication/auth/reset-password`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    });
    yield put(resetPasswordSuccess(result));
  } catch (err) {
    yield put(resetPasswordError(err));
  }
}

export function* resetPassword() {
  return yield takeLatest(RESET_PASSWORD, postResetPassword);
}

export function* getPermissions() {
  try {
    const localOperationsMode = false && localHostnames.includes(window.location.hostname);
    let localStorageOperations;
    if (localOperationsMode) {
      try {
        // localStorageOperations = JSON.parse(localStorage.allowedOperationsNew);
      } catch (err) {
        //
      }
    }
    const [{ userGroups, operations: allowedOperationsNew }] = yield all([
      localStorageOperations && Array.isArray(localStorageOperations)
        ? call(boundResolve, { operations: localStorageOperations })
        : call(requestBackend, '/authorisation/operations/allowed'),
    ]);

    if (localOperationsMode) {
      localStorage.allowedOperationsNew = JSON.stringify(allowedOperationsNew);
    }

    const userGroupIds = userGroups.map((userGroup) => parseInt(userGroup, 0));

    localStorage.userGroupIds = userGroupIds;

    yield put(
      loadPermissionsSuccess({
        allowedOperationsNew,
        userGroupIds,
      }),
    );
  } catch (err) {
    yield put(loadPermissionsError(err));
  }
}

export function* loadPermissions() {
  return yield takeLatest(LOAD_PERMISSIONS, getPermissions);
}

export function* getSettings() {
  try {
    const settings = yield call(requestBackend, '/cbe/settings');

    let localTranslations = {};
    if (localStorage.disableTranslations !== 'true' && settings.features?.microservices?.translations) {
      try {
        localTranslations = yield call(requestBackend, '/translations/public/tenant-translations');
      } catch (err) {
        // Do nothing
      }
    }

    let staticPages = [];
    if (
      localStorage.token &&
      isCandidateIntersect(getParsedUser().accountTypes) &&
      settings?.features?.microservices?.staticPages
    ) {
      try {
        const staticPagesResponse = yield call(requestBackend, '/static-pages/my?pageTypes=home');
        if (staticPagesResponse && staticPagesResponse.items) {
          let maxGroupId = 0;
          staticPagesResponse.items.forEach((item) => {
            item.userGroupIds.forEach((userGroupId) => {
              if (userGroupId > maxGroupId) {
                maxGroupId = userGroupId;
              }
            });
          });
          staticPages = staticPagesResponse.items.sort((prev) => (prev.userGroupIds.includes(maxGroupId) ? -1 : 1));
        }
      } catch (err) {
        // Do nothing
      }
    }

    let customFieldsModel = {};
    try {
      if (localStorage.token) {
        customFieldsModel = yield call(requestBackend, '/my/candidate-profile/custom-fields/model');
      }
    } catch (err) {
      // Do nothing
    }

    if (settings.locale === 'ru') {
      // @ts-expect-error
      moment.locale('ru', momentLocaleRu);
    }

    const preparedSettings = {
      ...settings,
      staticPages,
      customFieldsModel,
    };

    window.settings = preparedSettings;
    window.hrefTarget = settings.features.ui.runEvaInASingleTab ? '_self' : '_blank';
    connectTranslations(localTranslations);
    yield put(loadSettingsSuccess(preparedSettings));
  } catch (err) {
    yield put(loadSettingsError(err));
  }
}

export function* loadSettings() {
  return yield takeLatest(LOAD_SETTINGS, getSettings);
}

// eslint-disable-next-line import/no-default-export
export default [
  signInWatcher,
  signUpWatcher,
  loadUserProfile,
  restorePasswordWatcher,
  resetPassword,
  loadPermissions,
  loadSettings,
];
