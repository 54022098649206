import PropTypes from 'prop-types';
import React from 'react';

import JobDetails from 'containers/JobDetails';

const JobDetailsWrapper = (props) => <JobDetails jobCode={props.match.params.jobCode} socket={props.socket} wrapped />;

JobDetailsWrapper.propTypes = {
  socket: PropTypes.object,
  match: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default JobDetailsWrapper;
