import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { GenericContext } from '@eva/emf/app/shared/constants';

import { updateUserProfile } from 'containers/App/actions';

import { getPatchedJsonSchema } from './CardCustomFields';

function PreviewCustomFields(props) {
  const { userProfile } = props;
  const {
    settings: {
      customFieldsModel: { jsonSchema, uiSchema },
    },
  } = React.useContext(GenericContext);

  const { current: abortController } = React.useRef(new AbortController());
  React.useEffect(() => () => abortController.abort(), [abortController]);

  // @ts-expect-error
  const patchedJsonSchema = getPatchedJsonSchema(jsonSchema, uiSchema);

  const filledTotal = Object.entries(userProfile.customFields || {}).filter(([key, value]) => {
    if (!patchedJsonSchema.properties[key]) {
      return false;
    }
    return Array.isArray(value) ? value.length : value;
  }).length;

  return (
    <div>
      <div className="panel-menu-item-summary">
        {!!filledTotal &&
          translate('{{ filledTotal }} fields entered', {
            filledTotal,
          })}
        {!filledTotal && translate('All fields empty')}
      </div>
    </div>
  );
}

PreviewCustomFields.contextTypes = {
  settings: PropTypes.object.isRequired,
};

PreviewCustomFields.propTypes = {
  userProfile: PropTypes.object.isRequired,
  dispatchUpdateUserProfile: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error
  dispatchUpdateUserProfile: (...args) => dispatch(updateUserProfile(...args)),
});

// eslint-disable-next-line import/no-default-export
export default connect(() => ({}), mapDispatchToProps)(PreviewCustomFields);
