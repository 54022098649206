/* eslint-disable no-restricted-properties */
import React from 'react';

import NotFoundPage from '@eva/emf/app/shared/ui/NotFoundPage';
import ForbiddenPage from '@eva/emf/app/shared/ui/ForbiddenPage';
import SignInLocal from '@eva/emf/app/components/SignInLocal';
import { authPrefix, agentPrefix, userAccountTypes, routesNames } from 'shared/constants';
import {
  getAccountTypes,
  getParsedUser,
  isCandidateIntersect,
  setTitle,
  isPathStartWith,
  isPathEqual,
  getQueryVariables,
  redirectTo,
} from 'shared/functions';

import App from 'containers/App';
import JobDetailsWrapper from 'components/JobDetailsWrapper';
import MessengerCandidate from 'containers/MessengerCandidate';
import { RedirectorToNewPath, Redirector } from 'containers/Redirector';

const redirectUrls = [
  {
    oldPath: '/authentication',
    newPath: '/auth/deeplinks',
  },
  {
    oldPath: '/manage-subscription',
    newPath: '/auth/manage-subscription',
  },
];

const MainComponent = (props: any) => {
  setTitle();

  // TODO deprecated, remove in DEV-25601
  // because we gonna move logic to authentification repo
  // after move path from root (authentification, job-details)
  if (isPathEqual('/')) {
    if (localStorage.firstName) {
      const { redirect } = getQueryVariables() as any;
      const newPath = redirect ? `/auth/sign-in?redirect=${redirect}` : '/auth/sign-in';
      return <RedirectorToNewPath isExternalPath={true} newPath={newPath} />;
    }

    return <RedirectorToNewPath newPath={'/candidate'} />;
  }

  if (isPathStartWith(authPrefix) || isPathStartWith(agentPrefix)) {
    return <NotFoundPage />;
  }

  for (const redirectUrl of redirectUrls) {
    if (isPathStartWith(redirectUrl.oldPath)) {
      const newPath = window.location.href.replace(redirectUrl.oldPath, redirectUrl.newPath);

      return redirectTo(newPath);
    }
  }

  if (isPathStartWith('/job-details/')) {
    return <App {...props} />;
  }

  const accountTypes = getAccountTypes(getParsedUser());
  if (accountTypes.length && !isCandidateIntersect(accountTypes)) {
    return <ForbiddenPage />;
  }

  return <App {...props} />;
};

export const routes = [
  {
    path: '/auth/sign-in',
    name: routesNames.signInLocal,
    component: () => (<SignInLocal redirectPath="/candidate" accountTypes={[userAccountTypes.candidate]} />) as any,
  },
  {
    path: '/',
    name: routesNames.home,
    component: MainComponent,
    routes: [
      {
        path: '/candidate/chat',
        name: routesNames.messenger,
        component: MessengerCandidate,
      },
      {
        path: '/candidate/recommended',
        name: routesNames.recommended,
        component: MessengerCandidate,
      },
      {
        path: '/candidate/my-jobs',
        name: routesNames.myJobs,
        component: MessengerCandidate,
      },
      {
        path: '/candidate/home',
        name: routesNames.home,
        component: MessengerCandidate,
      },
      {
        path: '/candidate/my-profile',
        name: routesNames.myProfile,
        component: MessengerCandidate,
      },
      {
        path: '/candidate',
        name: routesNames.redirector,
        component: Redirector as any,
      },
      {
        path: '/job-details/:jobCode',
        name: routesNames.jobDetails,
        component: JobDetailsWrapper,
      },
      {
        path: '*',
        name: '😀',
        component: NotFoundPage,
      },
    ],
  },
];
