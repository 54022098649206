import React from 'react';

import { requestBackend } from '@eva/emf/app/utils/request';
import { validateFile } from 'shared/functions';

type useFileUploaderType = {
  acceptExtensions: string;
};

export function useFileUploader(config: useFileUploaderType = { acceptExtensions: '' }) {
  const { acceptExtensions } = config;

  const [uploadButtonRef, setUploadButtonRef] = React.useState(null);
  const [progress, setProgress] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [fileUrl, setFileUrl] = React.useState('');

  const [xhr] = React.useState(() => {
    const xhrRequest = new XMLHttpRequest();
    xhrRequest.upload.addEventListener(
      'progress',
      (evt) => setProgress(evt.lengthComputable ? (evt.loaded / evt.total) * 0.99 : 0.99),
      false,
    );
    xhrRequest.upload.addEventListener('load', () => setTimeout(() => setProgress(1), 100), false);
    return xhrRequest;
  });

  const [fileInput] = React.useState(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', acceptExtensions);
    input.onchange = (
      evt: Event & {
        target: EventTarget & { files: FileList };
      },
    ) => {
      const file = evt.target.files.item(0);

      const resultValidate = validateFile(file);

      if (resultValidate) {
        return alert(resultValidate);
      }

      setFile(file);
      setProgress(progress);

      requestBackend('/files/url', {
        method: 'POST',
        body: JSON.stringify({
          fileName: file.name,
        }),
      }).then((res: { url: string }) => {
        setFileUrl(res.url.substr(0, res.url.indexOf('?')));
        xhr.open('put', res.url, true);
        xhr.send(file);
      });
    };
    return input;
  });

  const handleOnClick = React.useCallback(() => {
    fileInput.click();
  }, [fileInput]);

  const resetFile = React.useCallback(() => {
    setFile(null);
    setFileUrl(null);
    setProgress(0);
    fileInput.value = null;
  }, [fileInput]);

  React.useEffect(() => {
    if (uploadButtonRef) {
      uploadButtonRef.addEventListener('click', handleOnClick, false);
    }
    return () => {
      if (uploadButtonRef) {
        uploadButtonRef.removeEventListener('click', handleOnClick, false);
      }
    };
  }, [handleOnClick, uploadButtonRef]);

  return {
    setUploadButtonRef,
    resetFile,
    file,
    fileUrl,
    progress,
  };
}
